import { render, staticRenderFns } from "./NumberFilter.vue?vue&type=template&id=b70ab462&scoped=true&"
import script from "./NumberFilter.vue?vue&type=script&lang=js&"
export * from "./NumberFilter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b70ab462",
  null
  
)

export default component.exports